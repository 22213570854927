<template>
  <v-app>
    <v-row class="ma-0">
      <v-col cols="8" class="white image-bg pa-0" />
      <v-col class="white d-flex align-center justify-center">
        <v-card
          class="white d-flex justify-center align-center flex-column"
          height="350px"
          width="300px"
          flat
          light
        >
          <v-card-title
            :color="$vuetify.theme.themes.dark.primary"
            class="text-h5 text-uppercase"
            primary-title
          >
            <v-icon
              v-if="logo == null"
              size="100"
              :color="$vuetify.theme.themes.dark.primary"
              >mdi-account-circle</v-icon
            >
            <v-img
              v-else
              :src="`https://encode-index.s3.amazonaws.com/${logo}`"
              style="object-fit: cover"
              max-height="200"
              max-width="250"
            ></v-img>
          </v-card-title>
          <v-card-text>
            <v-form
              class="d-flex flex-column justify-center"
              @submit.prevent="login"
            >
              <v-text-field
                :color="$vuetify.theme.themes.dark.primary"
                v-model="user.email"
                type="email"
                label="Email"
                placeholder="Email"
                outlined
                dense
                @change="getLogoByEmail()"
              />
              <v-text-field
                :color="$vuetify.theme.themes.dark.primary"
                v-model="user.password"
                @click:append="showPass = !showPass"
                label="Senha"
                placeholder="Senha"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
                dense
                hide-details
              />
              <v-btn
                class="mt-5"
                type="submit"
                :color="$vuetify.theme.themes.dark.primary"
                dark
              >
                Entrar
              </v-btn>

              <small class="error--text text-caption text-center">{{
                message
              }}</small>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    message: "",
    logo: null,
    showPass: false,
    user: {
      email: null,
      password: null,
    },
  }),
  methods: {
    login: function () {
      this.$api
        .post("/auth/login", this.user)
        .then((res) => {
          this.$session.start();
          this.$cookies.set("token", res.data.token);
          this.$cookies.set("logo", res.data.user.system_client_logo);
          this.$cookies.set("system_client_id", res.data.user.system_client_id);
          this.$cookies.set("user_id", res.data.user.id);
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          this.message = err.response.data.message;
        });
    },
    getLogoByEmail: function () {
      this.$api
        .post("/auth/prelogin", this.user)
        .then((res) => {
          this.$cookies.set("logo", res.data.user.system_client_logo);
          this.logo = res.data.user.system_client_logo;
        })
        .catch((err) => {
          this.message = err.response.data.message;
        });
    },
  },
};
</script>

<style scoped>
.image-bg {
  background: url("https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=773&q=80")
    no-repeat;
  background-size: cover;
}
</style>